import Page from '../../classes/Page';
import each from 'lodash/each';
import ProjectSection from './ProjectSection';

export default class WebsitesPage extends Page {
	constructor(el) {
		super({
			element: el,
		});
		this.id = 'websites';
	}

	create() {
		if (this.template != this.id) return;

		if (!this.isReady) super.createComponent();

		if (!this.isCreated) {
			this.components = {
				project: new ProjectSection(),
			};
			this.isCreated = true;
		}

		// Create Components
		each(this.components, (component) => {
			component.create();
		});
		console.log(`🔼 ${this.id} is created`);
	}

	show() {}

	hide() {
		return new Promise((resolve) => {
			this.destroy();

			GSAP.to(this.element, {
				autoAlpha: 0,
				onComplete: resolve,
			});
		});
	}

	addEventListeners() {
		each(this.components, (component) => {
			component.addEventListeners();
		});
	}

	removeEventListeners() {
		each(this.components, (component) => {
			component.removeEventListeners();
		});
	}

	destroy() {
		super.destroy();
		this.removeEventListeners();

		each(this.components, (component) => {
			component.destroy();
		});
	}
}
