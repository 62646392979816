import Component from '../../classes/Component';

import GSAP from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
GSAP.registerPlugin(ScrollTrigger);

import each from 'lodash/each';
import map from 'lodash/map';
import Lightbox from '../../components/Lightbox';

export default class Mdesign extends Component {
	constructor() {
		super({
			element: '.mdesign',
			elements: {
				items: '.mdesign__gallery__item',
			},
		});

		this.lightbox = new Lightbox();
	}

	create() {
		super.createComponent();
		this.shuffleItems();
		this.lightbox.create(this.elements.items);
		this.addEventListeners();
	}

	shuffleItems() {
		// console.log(this.elements.items);
		// let leftItems = 7;
		// let rightItems = 7;
		// const items = this.elements.items;
		// // 1 item from left, 1 from right, repeat until all items are added
		// map(this.elements.items, (item, index) => {
		// 	if (index % 2 === 0) {
		// 		item.style.order = leftItems;
		// 		leftItems--;
		// 	} else {
		// 		item.style.order = rightItems;
		// 		rightItems--;
		// 	}
		// });
	}

	openLightbox(item, type = 'image') {
		console.log('Open Lightbox', this.isDragging);
		if (this.isDragging) return;
		this.lightbox.setActiveImage(item, type);
		this.lightbox.open();
	}

	addEventListeners() {
		console.log(
			'addEventListeners Mdesign',
		);
		each(this.elements.items, (item) => {
			item.addEventListener('click', () => {
				this.openLightbox(item);
			});
		});
	}
	removeEventListeners() {
		each(this.elements.items, (item) => {
			item.removeEventListener('click', () => {
				this.openLightbox(item);
			});
		});
	}

	destroy() {
		super.destroy();
	}
}
