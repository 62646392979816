// Resize Handler
import GlobalHandler from '../../classes/GlobalHandler';

// Three
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

// Libraries
import GSAP from 'gsap';

// Classes
import Canvas from '../../classes/Canvas';

// Canvas Components
import Gallery from './Gallery';
import Camera from './Camera';
import Debug from './Debug';
import Lights from './Lights';
import Raycaster from './Raycaster';

import LogoScene from './LogoScene';

// Utils
import { lerp } from '../../utils/utils';
import { clamp } from 'three/src/math/MathUtils';
import map from 'lodash/map';

// 3D Models
import Roza from './Models/Roza';

// Shaders
import vertexShader from '../../../shared/shaders/vertex.glsl';
import fragmentShader from '../../../shared/shaders/fragment.glsl';

// Scene & renderer are created in Canvas.js class
export default class Experience extends Canvas {
	constructor(el) {
		super(el);

		this.speed = 0;

		// this.createGui();
		// this.models = this.loadModels();
		this.loadTextures();

		this.isReady = false; // update method is called only when true

		this.camera = new Camera({ sizes: this.sizes });
		this.scene.add(this.camera.el);

		this.createLogoScene();

		// this.controls = new OrbitControls(this.camera, this.element);
		// this.controls.enableDamping = true;

		this.lights = new Lights({
			scene: this.scene,
		});

		this.mouse = new THREE.Vector2();
		this.touch = {
			start: 0,
			end: 0,
		};
		this.isTouch = false;

		this.clock = new THREE.Clock();
		this.oldElapsedTime = 0;

		this.addEventListeners();
		this.onResize();
		this.isReady = true;
		GlobalHandler.registerResize(this.onResize.bind(this));

		window.experience = this;
	}

	loadTextures() {
		this.textureLoader = new THREE.TextureLoader();
		this.logoImg = this.textureLoader.load('/images/textureTest.png');
		// this.selectionImages = {
		// 	designs: this.textureLoader.load('/images/designs.jpg'),
		// 	websites: this.textureLoader.load('/images/websites.jpg'),
		// };
	}

	/**
	 * Component
	 */
	createLogoScene() {
		if (!this.logoScene) {
			this.logoScene = new LogoScene({
				scene: this.scene,
				logoImg: this.logoImg,
			});
			this.logoScene.isReady = true;
		} else {
			this.logoScene.createTimeline();
		}
	}

	/**
	 * Utility
	 */
	createGSAP() {
		this.timeline = GSAP.timeline();
		this.xSetter = GSAP.quickSetter('.dot', 'y', 'px');
		GSAP.utils.pipe(
			GSAP.utils.clamp(0, 100), //make sure the number is between 0 and 100
			GSAP.utils.snap(5), //snap to the closest increment of 5
			GSAP.quickSetter('.dot', 'y', 'px') //apply it to the #id element's x property and append a "px" unit
		);
	}

	createGui() {
		/* Add all variables to debugObject*/
		this.debugObject = {};
		this.debugObject.progress = 0;
		this.debugObject.progressTarget = 0;

		this.debug = new Debug(this.debugObject);
	}

	createGallery() {
		// this.gallery = new Gallery({
		// 	scene: this.scene,
		// 	sizes: this.sizes,
		// });
		this.raycaster = new Raycaster({
			// meshes: this.gallery.meshes
		});
	}

	/**
	 * Textures & Models
	 */
	// loadModels() {
	// 	return [
	// 		(this.roza = new Roza({
	// 			scene: this.scene,
	// 			sizes: this.sizes,
	// 			modelPath: 'models/roza.glb',
	// 		})),
	// 	];
	// }

	// This method is called in app.js at onPreloaded()
	updateImages() {
		if (this.gallery.meshes.length === 0) {
			this.gallery.createMeshes(this.gallery.imageBounds);
			this.isReady = true;
		} else {
			this.gallery.updateMeshes(this.gallery.imageBounds);
		}
	}

	/**
	 * Elements & Lights
	 */

	update() {
		this.elapsedTime = this.clock.getElapsedTime();

		// console.log(this.elapsedTime);

		// Calculate the needed rotation
		const targetRotationX = -this.mouse.y * Math.PI; // or any smaller number for subtler movement
		const targetRotationY = this.mouse.x * Math.PI; // or any smaller number for subtler movement

		// Update Image mesh
		// this.selectionScene.update(this.mouse.x, this.mouse.y);

		// Smoothly interpolate towards the target rotation
		// this.camera.el.rotation.x +=
		// 	0.05 * (-targetRotationX * 0.5 - this.camera.el.rotation.x);
		// this.camera.el.rotation.y +=
		// 	0.05 * (-targetRotationY * 0.5 - this.camera.el.rotation.y);

		this.logoScene.update(this.elapsedTime, targetRotationX, targetRotationY);
		// this.logoScene.logoMesh.rotation.x +=
		// 	0.5 * (targetRotationX - this.logoScene.logoMesh.rotation.x) * Math.PI;

		// this.logoScene.logoMesh.rotation.y +=
		// 	0.5 * (targetRotationY - this.logoScene.logoMesh.rotation.y) * Math.PI;

		// this.logoScene.logoMesh.visible = false;
		// this.logoScene.cubeCamera.update(this.renderer, this.scene);
		// this.logoScene.logoMesh.visible = true;

		// Cast a ray
		// this.raycaster.el.setFromCamera(this.mouse, this.camera.el);
		// this.raycaster.update();
		// if (this.raycaster.currentIntersect) {
		// 	this.raycaster.currentIntersect.object.material.uniforms.uMouse.value =
		// 		this.mouse;
		// }
		// Tick

		// Update uniforms

		// this.effect1.uniforms['uTime'].value = this.elapsedTime * 0.45;

		// Controls update
		// this.controls.update();

		// Scene
		this.renderer.render(this.scene, this.camera.el);
	}

	onResize() {
		// Update sizes
		super.onResize();

		// Update camera
		this.camera.resizeCamera(this.sizes);

		// Update renderer
		this.renderer.setSize(this.sizes.width, this.sizes.height);
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

		// Update elements
		// this.gallery.sizes = this.sizes;

		// if (this.gallery.onResize) this.gallery.onResize();
	}

	addEventListeners() {
		// Handle mouse events
		window.addEventListener('mousemove', (event) => {
			this.mouse.x = (event.clientX / this.sizes.width) * 2 - 1;
			this.mouse.y = -(event.clientY / this.sizes.height) * 2 + 1;
		});

		// Handle touch events
		window.addEventListener('touchstart', (event) => {
			this.isTouch = true;
			updateTouchPosition(event);
		});

		window.addEventListener('touchmove', (event) => {
			if (this.isTouch) {
				updateTouchPosition(event);
			}
		});

		window.addEventListener('touchend', () => {
			this.isTouch = false;
		});

		const updateTouchPosition = (event) => {
			if (event.touches.length > 0) {
				// @TODO
				// const touchEvent = event.touches[0];
				// this.speed += -(event.touches[0] / this.sizes.height) + 0.5;
				// this.mouse.x = touchEvent.clientX / this.sizes.width - 0.5;
				// this.mouse.y = -(touchEvent.clientY / this.sizes.height) + 0.5;
			}
		};
	}

	destroy() {
		// super.destroy();
		this.logoScene.destroy();
	}
}
