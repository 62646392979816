import Component from '../../classes/Component';

import GSAP from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
GSAP.registerPlugin(ScrollTrigger);

import each from 'lodash/each';

export default class GraphicDes extends Component {
	constructor() {
		super({
			element: '.designs',
			elements: {
				graphic: '.graphicdes-1',
			},
		});
	}

	create() {
		super.createComponent();

		this.createTimeline();
	}

	createTimeline() {
		this.tl = GSAP.timeline();

		this.animation = GSAP.to(this.elements.graphic, {
			// x: 300,
			duration: 2,
			ease: 'in.expo',
			scrollTrigger: {
				trigger: this.elements.graphic[0],
				start: 'top top',
				scrub: true,
				// pinSpacing: false,
				pin: false,
				// markers: true,
				onUpdate: (self) => {
					if (self.direction === 1) {
						// animation is complete
						window.experience.logoScene.progress = self.progress;
					} else {
						// animation is in progress
						window.experience.logoScene.progress = self.progress;
					}
				},
			},
		});
	}

	clearTimeline() {
		if (this.animation) {
			this.animation.scrollTrigger.kill();
			this.animation.kill();
			this.animation = null;
			this.isReady = false;

			let triggers = ScrollTrigger.getAll();

			each(triggers, (trigger) => {
				trigger.kill();
			});

			ScrollTrigger.refresh();
		}
	}

	destroy() {
		super.destroy();
		this.clearTimeline();
	}
}
