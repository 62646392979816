import Component from '../../classes/Component';
import GSAP from 'gsap';
import each from 'lodash/each';

export default class ProjectSection extends Component {
	constructor() {
		super({
			element: '.projects__wrapper',
			elements: { items: '.projects__item' },
		});
	}

	create() {
		super.createComponent();

		this.createTimeline();
	}

	createTimeline() {
		each(this.elements.items, (item) => {
			GSAP.fromTo(
				item,
				{
					autoAlpha: 0,
					y: 100,
				},
				{
					autoAlpha: 1,
					y: 0,
					duration: 1,
					ease: 'power4.out',
					scrollTrigger: {
						trigger: item.firstChild,
						start: 'top bottom',
						end: 'bottom top',
						toggleActions: 'restart none none reverse',
					},
				}
			);
		});
	}

	destroy() {
		super.destroy();

		if (this.timeline) {
			this.timeline.kill();
			this.timeline = null;
		}
	}
}
