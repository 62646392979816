import NodeEmitter from '../../classes/NodeEmitter';

import * as THREE from 'three';

import GSAP from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
GSAP.registerPlugin(ScrollTrigger);

import vertexShader from '../../../shared/shaders/vertex.glsl';
import fragmentShader from '../../../shared/shaders/fragment.glsl';
import avVertex from '../../../shared/shaders/av.vert';
import avFragment from '../../../shared/shaders/av.frag';

import each from 'lodash/each';

import GlobalHandler from '../../classes/GlobalHandler';

export default class LogoScene {
	constructor({ logoImg, scene }) {
		this.isReady = false;
		this.coverSection = document.querySelector('#cover-home');
		this.logoImg = logoImg;
		this.scene = scene;

		this.createScene();
	}

	createScene() {
		this.geometries = [];

		this.uniforms = {
			uTime: { value: 0 },
		};

		this.geometry = new THREE.SphereGeometry(1.5, 64, 64);
		this.material = new THREE.ShaderMaterial({
			// wireframe: true,
			uniforms: this.uniforms,
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
			side: THREE.DoubleSide,
		});

		this.mesh = new THREE.Mesh(this.geometry, this.material);
		this.mesh.position.z = 0;
		this.scene.add(this.mesh);

		// Cube
		this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
			format: THREE.RGBAFormat,
			generateMipmaps: true,
			minFilter: THREE.LinearMipMapLinearFilter,
			encoding: THREE.sRGBEncoding,
		});

		this.cubeCamera = new THREE.CubeCamera(0.1, 10, this.cubeRenderTarget);

		const geo = new THREE.PlaneGeometry(1, 1, 1);

		this.sphereMaterial = new THREE.ShaderMaterial({
			uniforms: {
				tCube: { value: 0 },

				// Variant 1
				mRefractionRatio: { value: 0.6 },
				mFresnelBias: { value: 0.73 },
				mFresnelScale: { value: 4.22 },
				mFresnelPower: { value: 5.4 },

				uPattern1: { value: 0.1 },
				uTexture: { value: this.logoImg },
			},
			vertexShader: avVertex,
			fragmentShader: avFragment,
			side: THREE.DoubleSide,
			transparent: true,
			alphaTest: 0.5,
		});
		this.logoMesh = new THREE.Mesh(geo, this.sphereMaterial);
		this.logoMesh.scale.set(0.75, 0.75, 0.75);
		// this.scene.add(this.logoMesh);
	}

	createTimeline() {
		this.progress = 0;

		this.coverSection = document.querySelector('#cover-home');
		this.coverWrapper = document.querySelector('.cover__wrapper');
		if (!this.coverSection) return;
		this.coverWrapper.style.transform = `translate(0,0)`;

		this.animation = GSAP.to(this.coverWrapper, {
			// x: 300,
			duration: 2,
			// autoAlpha: 0,
			ease: 'in.expo',
			scrollTrigger: {
				trigger: this.coverSection,
				start: 'top top',
				scrub: true,
				// pinSpacing: false,
				// pin: true,
				onUpdate: (self) => {
					if (self.direction === 1) {
						// animation is complete
						this.progress = self.progress;
					} else {
						// animation is in progress
						this.progress = self.progress;
					}
				},
				// onLeave: () => {
				// 	NodeEmitter.emit('showMenu');
				// },
				// onEnterBack: () => {
				// 	NodeEmitter.emit('hideMenu');
				// },
			},
		});

		GSAP.set(this.coverWrapper, {
			autoAlpha: 1,
		});
		this.isReady = true;

		// setTimeout(() => ScrollTrigger.refresh(), 3000);
	}

	update(elapsedTime, rotateX, rotateY) {
		if (!this.isReady) return;

		this.mesh.rotation.z = elapsedTime * 0.1;
		this.mesh.rotation.x = rotateX * 0.1;
		this.mesh.rotation.y = rotateY * 0.1;
		this.sphereMaterial.uniforms.tCube.value = this.cubeRenderTarget.texture;

		this.mesh.material.uniforms.uTime.value = elapsedTime * 0.45;

		const value = this.progress > 0.25 ? Math.max(0.25, 1 - this.progress) : 1;
		this.mesh.scale.set(value, value, value);
	}

	clearTimeline() {
		if (this.animation) {
			this.animation.scrollTrigger.kill();
			this.animation.kill();
			this.animation = null;
			this.isReady = false;

			let triggers = ScrollTrigger.getAll();

			each(triggers, (trigger) => {
				trigger.kill();
			});

			ScrollTrigger.refresh();
			// this.mesh.scale.set(1, 1, 1);
			this.isReady = true;
		}
	}
	destroy() {
		this.clearTimeline();
		this.isReady = true;
	}
}
