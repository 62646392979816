import Page from '../../classes/Page';
import each from 'lodash/each';
import ContactForm from './form';

export default class ContactPage extends Page {
	constructor(el) {
		super({
			element: el,
			elements: {
				singlelines: '.contact__form-input',
				textareas: '.contact__form-textarea',
			},
		});
		this.id = 'contact';
	}

	create() {
		if (this.template != this.id) return;

		if (!this.isReady) super.createComponent();

		if (!this.isCreated) {
			this.components = {
				form: new ContactForm(),
			};
			this.isCreated = true;
		}

		this.addEventListeners(); // @TODO DOUBLE CHECK
		// Create Components
		each(this.components, (component) => {
			component.create();
		});
		console.log(`🔼 ${this.id} is created`);
	}

	show() {}

	hide() {
		return new Promise((resolve) => {
			this.destroy();

			GSAP.to(this.element, {
				autoAlpha: 0,
				onComplete: resolve,
			});
		});
	}

	addEventListeners() {
		console.log('EVENTS HELLOW????');
		each(this.components, (component) => {
			component.addEventListeners();
		});

		each(this.elements.singlelines, (element) => {
			element.addEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-input--filled');
				} else {
					element.classList.remove('contact__form-input--filled');
				}
			});
		});
		each(this.elements.textareas, (element) => {
			element.addEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-textarea--filled');
				} else {
					element.classList.remove('contact__form-textarea--filled');
				}
			});
		});
	}

	removeEventListeners() {
		each(this.components, (component) => {
			component.removeEventListeners();
		});

		each(this.elements.singlelines, (element) => {
			element.removeEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-input--filled');
				} else {
					element.classList.remove('contact__form-input--filled');
				}
			});
		});

		each(this.elements.textareas, (element) => {
			element.removeEventListener('input', () => {
				if (element.value.length > 0) {
					element.classList.add('contact__form-textarea--filled');
				} else {
					element.classList.remove('contact__form-textarea--filled');
				}
			});
		});
	}

	destroy() {
		super.destroy();
		this.removeEventListeners();

		each(this.components, (component) => {
			component.destroy();
		});
	}
}
