import Component from '../../classes/Component';
import GSAP from 'gsap';

export default class Preloader extends Component {
	constructor() {
		super({
			element: document.querySelector('.preloader'),
			elements: {
				images: document.querySelectorAll('[data-pre]'),
			},
		});

		this.createTimeline();
	}

	createTimeline() {
		this.timeline = GSAP.timeline({
			paused: true,
		});

		this.timeline.fromTo(
			this.element,
			{ autoAlpha: 1 },
			{ autoAlpha: 0, duration: 1, ease: 'power4.out' }
		);
	}

	// called from Loader
	createLoader(template) {
		this.length = 0;

		if (this.elements.images.length > 0)
			this.elements.images.forEach((img) => {
				// console.log('loadImg', img);
				this.loadImage(img);
			});

		if (this.elements.images.length === 0)
			setTimeout(this.onLoaded.bind(this), 100);
	}

	loadImage(img) {
		if (img.tagName.toLowerCase() === 'img') {
			// <img> tag
			// if (img.src) {
			// 	console.log(img, 'already loaded');
			// 	return this.onAssetLoaded(); // Image is already loaded
			// }

			const boundOnAssetLoaded = this.onAssetLoaded.bind(this);
			img.addEventListener('load', boundOnAssetLoaded);

			img.onload = function () {
				img.removeEventListener('load', boundOnAssetLoaded);
			};

			img.src = img.getAttribute('data-pre');
		} else {
			// other tags (for background image)
			const imageUrl = img.getAttribute('data-pre');
			const tempImage = new Image();

			tempImage.onload = () => {
				img.style.backgroundImage = `url(${imageUrl})`;
				this.onAssetLoaded();
				tempImage.onload = null; // optional, goes to garbage collection anyways
			};

			tempImage.src = imageUrl;
		}
	}

	// async loadImages() {
	// 	const images = Array.from(document.querySelectorAll('[data-pre]'));
	// 	const loadImage = (img) => {
	// 		return new Promise((resolve, reject) => {
	// 			img.src = img.getAttribute('data-pre');
	// 			img.onload = resolve;
	// 			img.onerror = reject;
	// 		});
	// 	};
	// 	await Promise.all(images.map(loadImage));
	// }

	onAssetLoaded() {
		this.length++;
		let imageLength = 0;

		if (this.elements.images) {
			imageLength += this.elements.images.length;
		}

		const percent = this.length / imageLength;

		if (percent === 1) {
			setTimeout(this.onLoaded.bind(this), 1000);
		}
	}

	onLoaded() {
		this.emit('completed');
	}

	show() {
		return new Promise((resolve) => {
			this.timeline.reverse().then(() => {
				resolve();
			});
		});
	}

	hide() {
		this.timeline.play();

		// .then(this.destroy.bind(this));
	}

	destroy() {
		// this.element.parentNode.removeChild(this.element);
	}
}
