import Component from '../../classes/Component';
import GSAP from 'gsap';
import each from 'lodash/each';
import map from 'lodash/map';
import pricingText from './pricing.content.js';

export default class PricingSection extends Component {
	constructor() {
		super({
			element: '.pricing',
			elements: {
				sectionHeading: '.pricing__section__heading',
				noisy: {
					bg: '.noisy__overlay__bg--color',
					gradient: '.noisy__overlay__bg--gradient',
				},
				block: {
					main: '.pricing__block',
					mainHeader: '.pricing__main__header',
					mainBtn: '.pricing__main__button',
				},
				pricingBoxesDev: '.pricing__box--dev',
				pricingBoxesDes: '.pricing__box--des',
				pricingTitles: '.pricing__main__title',
				pricingTexts: '.pricing__main__text'
			},
		});
	}

	querySelectExtras() {

		this.elementsToFade = [
			this.elements.block.mainBtn,
			...this.elements.pricingTexts,
			...this.elements.pricingTitles,
			...this.elements.pricingBoxesDes,
			...this.elements.pricingBoxesDev,
			this.elements.block.mainHeader,
			// this.elements.block.mainDescription,
			// this.elements.block.mainPrice.wrapper,
			// this.elements.block.mainBtn,
			// this.elements.block.services[0].children,
			// this.elements.block.services[1].children,
			// this.elements.block.services[2].children,
			// this.elements.block.services[3].children,
			// this.elements.block.services[4].children,
			// this.elements.block.services[5].children,
			// this.elements.block.services[6].children,
			// this.elements.block.services[7].children,
			// this.elements.block.servicesDescription.children,
		];
	}

	create() {
		super.createComponent();

		this.querySelectExtras();

		this.createTimeline();
		this.animateTimeline();
		this.activeType = 'standard';
		// console.log(this.element.querySelector('.pricing__type__selection.active'));
	}

	createTimeline() {
		console.log(`Creating Timeline`);
		this.timeline = GSAP.timeline({ paused: true });
		this.timeline
			.fromTo(
				this.elementsToFade,
				{ opacity: 1 },
				{
					opacity: 0,
					duration: 0.3,
					stagger: 0.05,
					ease: 'out.expo',
					// onComplete: () => this.updateTextContent(),
				}
			)
			.addLabel('opacityZero')
			.to(this.elementsToFade, {
				opacity: 1,
				duration: 1.2,
				ease: 'in.expo',
			});

		this.timelineInstant = GSAP.timeline({
			scrollTrigger: {
				trigger: this.elements.block.main,
				start: 'top bottom',
				end: 'bottom top',
				toggleActions: 'restart none none reverse',
				onLeaveBack: () => {
					this.timeline.seek('opacityZero');
					this.timeline.pause();
				},
				onEnter: () => this.timeline.reverse(),
			},
		});
	}

	animateTimeline() {
		this.timelineInstant.fromTo(
			this.elements.sectionHeading,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				ease: 'out.expo',
				duration: 1.25,
			},
			0
		);
	}

	changeType({ target }) {
		if (target.dataset.type === this.activeType) return;
		each(this.elements.block.bottom, (el) => el.classList.remove('active'));
		target.classList.add('active');
		this.activeType = target.dataset.type;

		this.playTimeline();
		this.updateNoisy();
	}

	killTimeline() {
		each(this.elementsToFade, (el) => {
			// if element has children, check each of child elements
			if (el.children && el.children.length > 0) {
				each(el.children, (child) => {
					GSAP.killTweensOf(child);
				});
			} else {
				GSAP.killTweensOf(el);
			}
		});
	}

	playTimeline() {
		this.timeline.seek(0);
		this.timeline.play();
	}

	showContent() {
		// this.timeline.play();
	}

	updateNoisy() {
		// Background Color Update
		GSAP.to(this.elements.noisy.bg, {
			background: `rgb(${this.noisyColorSet[this.activeType]})`,
			duration: 1.2,
			ease: 'out.expo',
		});

		// Gradient Color Update
		let gradStyle = getComputedStyle(this.elements.noisy.gradient);
		let initialColor = gradStyle.getPropertyValue('--data-grad-color').trim();

		this.elements.noisy.gradient.val = initialColor; // initial color

		GSAP.to(this.elements.noisy.gradient, {
			val: this.noisyColorSet[this.activeType],
			onUpdate: () =>
				this.elements.noisy.gradient.style.setProperty(
					'--data-grad-color',
					this.elements.noisy.gradient.val
				),
			duration: 1.2,
			ease: 'out.expo',
		});
	}

	addEventListeners() {
		each(this.elements.block.bottom, (el) => {
			el.addEventListener('click', (e) => {
				this.changeType(e);
			});
		});
	}

	removeEventListeners() {
		each(this.elements.block.bottom, (el) => {
			el.removeEventListener('click', (e) => {
				this.changeType(e);
			});
		});
	}

	destroy() {
		this.removeEventListeners();
		super.destroy();

		if (this.timeline) {
			this.timeline.kill();
			this.timeline = null;
		}

		if (this.timelineInstant) {
			this.timelineInstant.kill();
			this.timelineInstant = null;
		}
	}
}
