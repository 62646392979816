import Page from '../../classes/Page';
import ImageHoverEffect from '../../components/@avery-image-hover-effect';
import each from 'lodash/each';
import GraphicDes from './GraphicDes';
import DesignProcessSection from './DesignProcessSection';
import Mdesign from './Mdesign';
export default class DesignsPage extends Page {
	constructor() {
		super({
			element: '.designs',
			elements: {},
		});

		this.id = 'designs';
		this.isCreated = false;
	}

	create() {
		if (this.template != this.id) return;

		if (!this.isReady) super.createComponent();

		if (!this.isCreated) {
			this.components = {
				selection: new ImageHoverEffect({
					element: '.brandiden',
					elements: {
						figure: '.selection__figure',
						content: [
							{
								image: '#brand-averyano',
								button: '[data-select=averyano]',
							},
							{
								image: '#brand-chuiko',
								button: '[data-select=chuiko]',
							},
							// {
							// 	image: '#brand-piano',
							// 	button: '[data-select=piano]',
							// },
							// {
							// 	image: '#brand-prelab',
							// 	button: '[data-select=prelab]',
							// },
						],
					},
					activeClass: 'selection__image--active',
				}),
				graphicDes: new GraphicDes(),
				designProcess: new DesignProcessSection(),
				mDesign: new Mdesign(),
				// about: new AboutSection(),
			};
			this.isCreated = true;
		}

		// Create Components
		each(this.components, (component) => {
			component.create();
		});
		console.log(`🔼 ${this.id} is created`);
	}

	show() {}

	hide() {
		return new Promise((resolve) => {
			this.destroy();

			GSAP.to(this.element, {
				autoAlpha: 0,
				onComplete: resolve,
			});
		});
	}

	addEventListeners() {
		each(this.components, (component) => {
			component.addEventListeners();
		});
	}

	removeEventListeners() {
		each(this.components, (component) => {
			component.removeEventListeners();
		});
	}

	destroy() {
		super.destroy();
		this.removeEventListeners();

		each(this.components, (component) => {
			component.destroy();
		});

		// Removes scroll trigger instances
		const scrolltriggerElements = document.querySelectorAll('.pin-spacer');
		each(scrolltriggerElements, (pinSpacer) => {
			const parent = pinSpacer.parentElement;

			while (pinSpacer.firstChild) {
				parent.appendChild(pinSpacer.firstChild);
			}

			parent.removeChild(pinSpacer);
		});
	}

	update() {
		if (
			this.components &&
			this.components.selection &&
			this.components.selection.isReady
		)
			this.components.selection.update();
	}

	updateMousePos(e) {
		if (
			this.components &&
			this.components.selection &&
			this.components.selection.isReady
		)
			this.components.selection.updateMousePos(e);
	}
}
