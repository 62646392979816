import Component from '../../classes/Component';
import NodeEmitter from '../../classes/NodeEmitter';

import each from 'lodash/each';
import Swiper, { Navigation } from 'swiper';

import { insertAfter } from '../../utils/utils';

export default class DesignProcessSection extends Component {
	constructor() {
		super({
			element: '.process',
			elements: {
				items: '.process__item',
				steps: '.process__steps',
				swiper: '.swiper',
				swiperWrapper: '.swiper-wrapper',
				navFullscreen: '.process__item__icon',
				navArrows: '.process__arrow',
				navNumbers: {
					current: '#swiper-current',
					total: '#swiper-total',
				},
			},
		});

		this.isFullscreen = false;
		window.isFullscreen = this.isFullscreen;
	}

	create() {
		super.createComponent();

		this.createSwiper();
		this.saveSvgs();
		this.addEventListeners();
	}

	createSwiper() {
		this.swiper = new Swiper(this.elements.swiper, {
			direction: 'horizontal',
			loop: true,
			modules: [Navigation],
			navigation: {
				prevEl: '.process__left__arrow',
				nextEl: '.process__right__arrow ',
			},
			on: {
				slideChangeTransitionEnd: () => {
					this.elements.navNumbers.current.textContent =
						'0' + (this.swiper.realIndex + 1);
				},
			},
			controller: {
				control: 'globalController',
				by: 'container',
			},
		});

		this.elements.navNumbers.total.textContent =
			'0' + this.swiper.slides.length;
	}

	enterFullscreen({ target }) {
		if (!this.isFullscreen) {
			this.elements.swiper.classList.add('fullscreen', 'swiper--clone');
			this.elements.navFullscreen.classList.add('process__item__icon--cross');
			this.elements.navFullscreen.innerHTML = this.crossIcon;

			this.getSwiperBounds();
			this.makeSwiperFixed();

			this.isFullscreen = true;
			window.isFullscreen = this.isFullscreen;
			NodeEmitter.emit('stopScroll');
			NodeEmitter.emit('hideMenu');
		} else {
			this.elements.swiper.classList.remove('fullscreen', 'swiper--clone');
			this.elements.navFullscreen.classList.remove(
				'process__item__icon--cross'
			);
			this.elements.navFullscreen.innerHTML = this.fullIcon;

			this.emptyFiller.remove();

			this.swiper.update();

			this.isFullscreen = false;
			window.isFullscreen = this.isFullscreen;

			NodeEmitter.emit('startScroll');
			NodeEmitter.emit('showMenu');
		}
	}

	getSwiperBounds() {
		this.swiperBounds = this.swiper.el.getBoundingClientRect();
	}

	makeSwiperFixed() {
		// document.body.insertAdjacentHTML('beforeend', html);

		this.emptyFiller = document.createElement('div');
		this.emptyFiller.id = 'empty-filler';
		this.emptyFiller.style.width = `${this.swiperBounds.width}px`;
		this.emptyFiller.style.height = `${this.swiperBounds.height}px`;
		insertAfter(this.elements.steps, this.emptyFiller);

		this.swiper.el.classList.add('swiper--clone');
		this.swiper.update();
	}

	showNav() {
		this.elements.navFullscreen.classList.add('active');

		each(this.elements.navArrows, (icon) => {
			icon.classList.add('active');
		});
	}

	hideNav() {
		this.elements.navFullscreen.classList.remove('active');

		each(this.elements.navArrows, (icon) => {
			icon.classList.remove('active');
		});
	}

	addEventListeners() {
		// Show/Hide the nav
		this.elements.swiper.addEventListener(
			'mouseenter',
			this.showNav.bind(this)
		);

		this.elements.swiper.addEventListener(
			'mouseleave',
			this.hideNav.bind(this)
		);

		// Fullscreen
		this.elements.navFullscreen.addEventListener(
			'click',
			this.enterFullscreen.bind(this)
		);
	}

	removeEventListeners() {
		this.elements.swiper.removeEventListener(
			'mouseenter',
			this.showNav.bind(this)
		);
		this.elements.swiper.removeEventListener(
			'mouseleave',
			this.hideNav.bind(this)
		);

		// Fullscreen
		this.elements.navFullscreen.removeEventListener(
			'click',
			this.enterFullscreen.bind(this)
		);
	}

	destroy() {
		super.destroy();
		this.swiper.destroy();
	}

	saveSvgs() {
		this.fullIcon = this.elements.navFullscreen.innerHTML;
		this.crossIcon = `<svg class="process__item__icon--svg process__item__icon--svg--cross" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.57843 0.589049L12 9.01026L20.3779 0.632682C20.563 0.435716 20.7859 0.27815 21.0334 0.169433C21.2808 0.0607153 21.5476 0.00308562 21.8179 0C22.3965 0 22.9515 0.229853 23.3606 0.638994C23.7698 1.04813 23.9996 1.60305 23.9996 2.18166C24.0047 2.44914 23.9551 2.71483 23.8538 2.96244C23.7525 3.21004 23.6017 3.43434 23.4106 3.62156L14.9235 11.9991L23.4106 20.4858C23.7702 20.8376 23.981 21.3139 23.9996 21.8166C23.9996 22.3952 23.7698 22.9501 23.3606 23.3593C22.9515 23.7684 22.3965 23.9983 21.8179 23.9983C21.5398 24.0098 21.2624 23.9634 21.0033 23.862C20.7441 23.7606 20.5089 23.6064 20.3125 23.4092L12 14.988L3.60025 23.3874C3.4159 23.5778 3.19567 23.7298 2.95227 23.8347C2.70887 23.9395 2.44711 23.9951 2.18211 23.9983C1.60347 23.9983 1.04853 23.7684 0.639376 23.3593C0.230218 22.9501 0.000354986 22.3952 0.000354986 21.8166C-0.00473176 21.5491 0.0448964 21.2834 0.146194 21.0358C0.247492 20.7882 0.398322 20.5639 0.589428 20.3767L9.07645 11.9991L0.589428 3.51247C0.229843 3.1607 0.0189863 2.68434 0.000354986 2.18166C0.000354986 1.60305 0.230218 1.04813 0.639376 0.638994C1.04853 0.229853 1.60347 0 2.18211 0C2.70573 0.00654498 3.20753 0.218166 3.57843 0.589049Z" fill="white"/>
		</svg>`;
	}
}
