import Component from '../../classes/Component';
import GSAP from 'gsap';
import each from 'lodash/each';
import map from 'lodash/map';

export default class FaqSection extends Component {
	constructor() {
		super({
			element: '.faq',
			elements: {
				items: '.faq__element',
			},
		});
	}

	create() {
		super.createComponent();
		this.createTimeline();
		this.animateTimeline();
	}

	createTimeline() {
		this.timelineInstant = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				start: 'top bottom',
				end: 'bottom top',
				toggleActions: 'restart none none reverse',
			},
		});
	}

	animateTimeline() {
		this.timelineInstant.fromTo(
			this.elements.items,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				ease: 'out.expo',
				duration: 1.25,
				stagger: 0.2,
			}
		);
	}

	animateHeight() {}

	revealDescription(el) {
		console.log(el);
		let target;
		let targetSvg;

		if (el.classList.contains('faq__element')) {
			target = el.querySelector('.faq__element__description');
			targetSvg = el.querySelector('.faq__element__svg');
		} else {
			const parent = el.closest('.faq__element');
			target = parent.querySelector('.faq__element__description');
			targetSvg = parent.querySelector('.faq__element__svg');
		}

		target.dataset.revealed =
			target.dataset.revealed === 'true' ? 'false' : 'true';
		console.log(target.dataset.revealed);
		const vh = target.dataset.revealed === 'true' ? '100vh' : '0vh';
		const rotate = target.dataset.revealed === 'true' ? '360deg' : '180deg';

		GSAP.to(target, {
			maxHeight: vh,
			duration: 0.3,
			ease: 'power4.out',
		});

		GSAP.to(targetSvg, {
			rotate: rotate,
			duration: 0.7,
			ease: 'power4.out',
			onComplete: () => {
				if (target.dataset.revealed === 'true')
					GSAP.set(targetSvg, { rotate: 0 });
			},
		});
	}

	addEventListeners() {
		each(this.elements.items, (el) => {
			el.addEventListener('click', (e) => {
				this.revealDescription(e.target);
			});
		});
	}

	removeEventListeners() {
		each(this.elements.items, (el) => {
			el.removeEventListener('click', (e) => {
				this.revealDescription(e.target);
			});
		});
	}

	destroy() {
		this.removeEventListeners();
		super.destroy();

		if (this.timelineInstant) {
			this.timelineInstant.kill();
			this.timelineInstant = null;
		}
	}
}
